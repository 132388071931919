import { lighten, saturate } from "@theme-ui/color"

/**
 * Theme for the app according to the Theme UI specification...
 * https://theme-ui.com/theme-spec
 */
export const theme = {
  breakpoints: [
    "30em", // 480px (phone)
    "36em", // 576px (small)
    "48em", // 768px (tablet)
    "56.25em", // 900px (small desktop)
    "75em", // 1200px (medium desktop)
  ],
  buttons: {
    primary: {
      color: "white",
      backgroundColor: "primary",
      borderRadius: 1000,
      display: "inline-block",
      fontSize: 1,
      fontWeight: 700,
      px: "3em",
      py: "1em",
      textDecoration: "none",
      transition: "background-color 0.2s ease-in-out",
      "&:hover": {
        bg: saturate("primary", 0.15),
      },
    },
    secondary: {
      color: "background",
      background: "secondary",
      transition: "background-color 0.2s ease-in-out",
      "&:hover": {
        bg: saturate("secondary", 0.15),
      },
    },
  },
  cards: {
    primary: {
      background: "white",
      borderTop: "1px solid #E7E8E9",
      borderBottom: "1px solid #E7E8E9",
      borderLeft: "1px solid #E7E8E9",
      borderRight: "1px solid #E7E8E9",
      borderRadius: "24px",
    },
  },
  colors: {
    text: "#001D50",
    background: "#F9FAFB",
    primary: "#CC112C",
    secondary: "#001D50",
    muted: "#5B5B5B",
    red: "#CC112C",
    blue: "#001D50",
    blueHover: "#092D6D",
    gray: "#5B5B5B",
    white: "#FFFFFF",
  },
  fonts: {
    body: "Helvetica, sans-serif",
    heading: "Helvetica, sans-serif",
    monospace: "Menlo, monospace",
  },
  fontSizes: [13, 14, 19, 24, 36],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700,
  },
  layout: {
    container: {
      paddingX: [0, 0, 0, 0, 4],
    },
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  links: {
    primary: {
      variant: "buttons.primary",
    },
  },
  sizes: {
    container: 1120,
  },
  space: [0, 4, 8, 16, 24, 32, 48, 64, 80, 96],
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    a: {
      color: "primary",
      transition: "color 0.1s ease-in-out",
      "&:hover": { color: lighten("primary", 0.15) },
    },
    hr: {
      borderColor: "muted",
    },
  },
  text: {
    paragraph: { maxWidth: 960 },
    h1: { fontSize: [3, null, null, 4] },
    h2: { fontSize: [2, null, null, 3] },
    b0: { fontSize: [1, null, null, 2] },
    b1: { fontSize: 1 },
    b2: { fontSize: 0 },
    label: { color: "gray", fontSize: 0 },
  },
}

export default theme
