import { useCallback, useEffect } from "react"
import { useLocation } from "react-router-dom"

// Used in conjunction with the embed script at public/static/js/embed.js
export default function IframeHeightReporter() {
  const location = useLocation()

  // Reports the minimum height required by setting height to 0 and reading the scrollHeight...
  const reportHeight = useCallback(() => {
    const initialHeight = document.body.style.height
    document.body.style.height = "0"
    window.parent.postMessage(
      {
        height: document.body.scrollHeight,
        message: "height:auto:adjust",
      },
      "*",
    )
    document.body.style.height = initialHeight
  }, [])

  // Report height on resize...
  useEffect(() => {
    window.addEventListener("resize", reportHeight)
    return () => {
      window.removeEventListener("resize", reportHeight)
    }
  }, [reportHeight])

  // Report height on mount and location change...
  useEffect(() => {
    reportHeight()
  }, [reportHeight, location])

  return null
}
