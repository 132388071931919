import { useCallback, useEffect, useRef, useState } from "react"
import { Box, Flex, Slider as ThemeSlider } from "theme-ui"

import sliderIcon from "icons/slider.svg"

export default function Slider({
  max = 1,
  onChange,
  passive,
  steps,
  sx,
  value,
  ...otherProps
}) {
  const markerRef = useRef()
  const [position, setPosition] = useState(0)

  const getNearestStep = useCallback(
    (value) =>
      steps
        ? steps.reduce((prev, curr) =>
            Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev,
          )
        : Math.round(value),
    [steps],
  )

  const update = useCallback(
    (value) => {
      value = getNearestStep(value)

      setPosition(value)
      onChange?.(value)

      markerRef.current.style.left = `${(value / max) * 100}%`
    },
    [getNearestStep, max, onChange, setPosition],
  )

  const handleChange = useCallback(
    ({ target: { value } }) => update(value),
    [update],
  )

  const handleKeyDown = useCallback(
    (event) => {
      const currentStep = steps.indexOf(position)

      switch (event.key) {
        case "ArrowLeft":
          if (currentStep > 0) {
            event.preventDefault()
            update(steps[currentStep - 1])
          }
          break
        case "ArrowRight":
          if (currentStep < steps.length - 1) {
            event.preventDefault()
            update(steps[currentStep + 1])
          }
          break
        default:
          break
      }
    },
    [position, steps, update],
  )

  useEffect(() => {
    value === undefined ? update(getNearestStep(max / 2)) : update(value)
  }, [getNearestStep, max, update, value])

  return (
    <Box
      sx={{
        bottom: 0,
        left: passive ? 0 : 32,
        pointerEvents: "none",
        position: "absolute",
        right: passive ? 0 : 32,
        top: 0,
        ...sx,
      }}
    >
      <Flex
        ref={markerRef}
        sx={{
          alignItems: "center",
          bottom: 0,
          justifyContent: "center",
          position: "absolute",
          top: 0,
          "&:before": {
            bottom: 0,
            borderRightColor: passive ? "primary" : "secondary",
            borderRightStyle: passive ? "dashed" : "solid",
            borderRightWidth: 1,
            content: "''",
            left: 0,
            position: "absolute",
            top: 0,
          },
        }}
      />
      {!passive && (
        <Flex
          sx={{ height: "100%", mx: [-24, null, -32], position: "relative" }}
        >
          <ThemeSlider
            min={0}
            max={max}
            onChange={handleChange}
            onKeyDown={steps ? handleKeyDown : null}
            sx={{
              background: "transparent !important",
              height: "100%",
              pointerEvents: "auto",
              position: "absolute",
              "&::-webkit-slider-thumb": {
                backgroundImage: `url(${sliderIcon})`,
                backgroundPosition: "center",
                borderRadius: 1000,
                backgroundRepeat: "no-repeat",
                backgroundSize: "50%",
                border: "2px solid white",
                height: [48, null, 64],
                marginTop: -3,
                position: "relative",
                width: [48, null, 64],
                "&:focus": {
                  backgroundColor: "primary",
                },
              },
            }}
            step="any"
            value={position}
            {...otherProps}
          />
        </Flex>
      )}
    </Box>
  )
}
