import PropTypes from "prop-types"
import { useCallback } from "react"
import { Button as ThemeButton } from "theme-ui"

import { trackEvent } from "utils/tracking"

/**
 * Button with tracking
 * @param children
 * @param onClick
 * @param trackingLabel   Tracks as "${trackingLabel} Button Clicked".
 * @param trackingParams  An object of parameters to track if needed.
 * @param otherProps
 * @returns {JSX.Element}
 * @constructor
 */
export default function Button({
  children,
  onClick,
  trackingLabel,
  trackingParams,
  ...otherProps
}) {
  const handleClick = useCallback(
    (event) => {
      trackingLabel &&
        trackEvent(`${trackingLabel} Button Clicked`, trackingParams)
      onClick?.(event)
    },
    [onClick, trackingLabel, trackingParams],
  )

  return (
    <ThemeButton onClick={handleClick} {...otherProps}>
      {children}
    </ThemeButton>
  )
}

Button.propTypes = {
  trackingLabel: PropTypes.string.isRequired,
}
