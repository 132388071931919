import { useMemo } from "react"

import { TIME } from "config/tools"

import useSearchParams from "hooks/useSearchParams"

export default function useToolParam(tool) {
  const params = useSearchParams()
  return useMemo(
    () =>
      Object.values(tool.options).find(
        ({ id }) => id === params.get(tool.id),
      ) || Object.values(tool.options)[tool === TIME ? 1 : 0],
    [params, tool],
  )
}
