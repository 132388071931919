import { createContext, useMemo, useEffect, useState } from "react"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import { ThemeProvider } from "theme-ui"

import { GlobalStyles, IframeHeightProvider, ScrollToTop } from "components"

import routes from "config/routes"

import { HomeScreen, ToolScreen } from "screens"

import theme from "theme"

export const EmbeddedContext = createContext(false)

export default function App() {
  // eslint-disable-next-line
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const isEmbedded = useMemo(
    // Is the app embedded (in an iFrame using embed URL param)
    () => new URLSearchParams(window.location.search).get("embed") !== null,
    [],
  )

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleWindowResize)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  })

  return (
    <EmbeddedContext.Provider value={isEmbedded}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          {isEmbedded && <IframeHeightProvider />}
          <GlobalStyles />
          <ScrollToTop />
          {isEmbedded ? (
            <ToolScreen />
          ) : (
            <Switch>
              <Route exact path={routes.home()}>
                <HomeScreen />
              </Route>
              <Route exact path={routes.tool()}>
                <ToolScreen />
              </Route>
              <Redirect to={routes.home()} />
            </Switch>
          )}
        </BrowserRouter>
      </ThemeProvider>
    </EmbeddedContext.Provider>
  )
}
