import { Box, Label } from "theme-ui"

export default function Option({ children, name, value, ...otherProps }) {
  const id = `${name}-${value}`
  return (
    <Box
      __css={{
        border: "none",
        color: "gray",
        flexGrow: 1,
        fontSize: 1,
      }}
    >
      <Box
        as="input"
        id={id}
        name={name}
        sx={{
          position: "absolute",
          opacity: 0,
          height: 0,
          width: 0,
          "&:checked": {
            "& + label": {
              backgroundColor: "blue",
              color: "white",
              cursor: "default",
            },
            "&:active + label, &:focus + label, &:active:hover + label": {
              backgroundColor: "primary",
              color: "white",
            },
          },
        }}
        type="radio"
        value={value}
        {...otherProps}
      />
      <Label
        htmlFor={id}
        sx={{
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: "1000px",
          cursor: "pointer",
          display: "flex",
          height: 48,
          px: 3,
          textAlign: "center",
          transition: "backgroundColor 0.2s ease-in-out",
          userSelect: "none",
          whiteSpace: "nowrap",
        }}
      >
        {children}
      </Label>
    </Box>
  )
}
