import { Box, Flex, Paragraph } from "theme-ui"

import { REDUCTION_VALUE_DISPLAY_FACTOR } from "config/tools"

export default function Values({ label, mini, values, mouthMap, scoreChart }) {
  const showDualValues = () => {
    const show = false
    if (!mini || mouthMap || scoreChart) {
      const show = true
      return show
    }
    return show
  }
  return (
    <Flex sx={{ flexDirection: "column", alignItems: "center" }}>
      <Flex
        sx={{
          color: "gray",
          fontSize: [16, null, 28],
          justifyContent: "center",
          lineHeight: 1,
        }}
      >
        {showDualValues(mouthMap, scoreChart, mini) && (
          <>
            <Box></Box>
            {values.control * REDUCTION_VALUE_DISPLAY_FACTOR || 0}{" "}
            <Box
              as="hr"
              sx={{
                backgroundColor: "#E7E8E9",
                border: "none",
                flex: "0 0 1px",
                mx: 2,
              }}
            />{" "}
          </>
        )}
        <Box as="span" sx={{ color: "red" }}>
          {values.parodontax * REDUCTION_VALUE_DISPLAY_FACTOR || 0}
        </Box>
        <Box></Box>
      </Flex>
      <Paragraph sx={{ mt: mini ? 0 : 2, textAlign: "center" }} variant="label">
        {label}
      </Paragraph>
    </Flex>
  )
}
