import { Paragraph } from "theme-ui"

export default function LegendValue({ children, color, ...otherProps }) {
  return (
    <Paragraph
      __css={{
        mr: 3,
        "&:before": {
          content: "''",
          display: "inline-block",
          bg: color,
          borderRadius: "100%",
          height: "0.75em",
          mr: 1,
          width: "0.75em",
        },
        "&:last-of-type": {
          mr: 0,
        },
      }}
      variant="label"
      {...otherProps}
    >
      {children}
    </Paragraph>
  )
}
