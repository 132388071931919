export const GUMS = {
  "dental-chart": [
    0.01, 0.096, 0.131, 0.164, 0.198, 0.232, 0.267, 0.301, 0.334, 0.369, 0.404,
    0.437, 0.471, 0.505, 0.539, 0.572, 0.606, 0.641, 0.675, 0.71, 0.743, 0.776,
    0.812, 0.844, 0.878, 0.913, 0.99,
  ],
  "mouth-map": [
    0.01, 0.1, 0.145, 0.191, 0.222, 0.251, 0.28, 0.312, 0.342, 0.37, 0.4, 0.43,
    0.466, 0.5, 0.536, 0.57, 0.601, 0.631, 0.66, 0.689, 0.718, 0.749, 0.779,
    0.809, 0.853, 0.9, 0.99,
  ],
}

export const TEETH = {
  "dental-chart": [
    0.01, 0.065, 0.085, 0.114, 0.133, 0.152, 0.181, 0.202, 0.22, 0.251, 0.27,
    0.288, 0.317, 0.337, 0.357, 0.384, 0.404, 0.425, 0.452, 0.471, 0.493, 0.52,
    0.538, 0.56, 0.586, 0.607, 0.627, 0.654, 0.675, 0.695, 0.723, 0.742, 0.761,
    0.792, 0.81, 0.829, 0.858, 0.878, 0.896, 0.925, 0.945, 0.99,
  ],
  "mouth-map": [
    0.01, 0.06, 0.086, 0.115, 0.145, 0.176, 0.203, 0.223, 0.24, 0.262, 0.282,
    0.302, 0.323, 0.34, 0.361, 0.381, 0.401, 0.421, 0.443, 0.467, 0.488, 0.512,
    0.535, 0.558, 0.581, 0.6, 0.618, 0.64, 0.659, 0.679, 0.699, 0.718, 0.736,
    0.76, 0.778, 0.797, 0.824, 0.856, 0.886, 0.914, 0.94, 0.99,
  ],
}
