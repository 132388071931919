import { useCallback, useRef } from "react"
import { AspectRatio, Box, Flex, Image, Paragraph } from "theme-ui"

import { Comparison, Legend } from "components"

import { brand } from "utils/url"

export default function ImageComparison({
  clinicalMeasure,
  legend,
  mini,
  mouthView,
  onChange,
  primaryView,
  singleChart,
  time,
  title,
  value,
  ...otherProps
}) {
  const isMobile = window.innerWidth < 900
  const leftLabelRef = useRef()
  const rightLabelRef = useRef()
  const progressRef = useRef()

  const spacings = clinicalMeasure.spacings[primaryView.id]

  const handleChange = useCallback(
    (value) => {
      value = spacings.indexOf(value)
      const factor = value / (spacings.length - 1)

      if (progressRef.current)
        progressRef.current.style.width = `${factor * 100}%`

      if (leftLabelRef.current)
        leftLabelRef.current.style.opacity = Math.min(factor * 2, 1)

      if (rightLabelRef.current)
        rightLabelRef.current.style.opacity = Math.max((1 - factor) * 2, 0)

      onChange?.(value)
    },
    [onChange, spacings],
  )
  return (
    <Box
      sx={{
        position: "relative",
        mx: [null, null, 0],
        py: [0, null, 3],
        height: isMobile ? "auto" : 454,
        width: "100%",
        top: singleChart ? (isMobile ? 0 : 200) : 0,
      }}
    >
      {!!title && (
        <Paragraph
          sx={{
            color: "#001D50",
            mb: 2,
            mx: isMobile ? 3 : 5,
            textAlign: isMobile ? "left" : "center",
          }}
        >
          {title}
        </Paragraph>
      )}
      <AspectRatio ratio={2}>
        <Comparison
          a={
            <Image
              src={`./static/media/charts/${clinicalMeasure.id}/control/${time.id}/${mouthView.id}/${primaryView.id}.png`}
              sx={{ display: "block" }}
            />
          }
          b={
            <Image
              src={`./static/media/charts/${clinicalMeasure.id}/parodontax/${time.id}/${mouthView.id}/${primaryView.id}.png`}
              sx={{ display: "block" }}
            />
          }
          mini={mini}
          onChange={handleChange}
          steps={spacings}
          value={spacings[value]}
          {...otherProps}
        >
          {!mini && (
            <Flex
              sx={{
                alignItems: "center",
                bottom: 0,
                left: 0,
                position: "absolute",
                right: 0,
                top: 0,
              }}
            >
              <Paragraph
                ref={leftLabelRef}
                sx={{ fontSize: "10px", lineHeight: 1, mt: 1 }}
              >
                <strong>
                  Effect of <br /> {brand}
                  <br /> Toothpaste^
                </strong>
              </Paragraph>
              <Box
                sx={{
                  backgroundColor: "#ADADAD",
                  flexGrow: 1,
                  height: 3,
                  mx: 2,
                  position: "relative",
                }}
              >
                <Box
                  ref={progressRef}
                  sx={{
                    backgroundColor: "red",
                    bottom: 0,
                    left: 0,
                    position: "absolute",
                    top: 0,
                  }}
                />
              </Box>
              <Paragraph
                ref={rightLabelRef}
                sx={{ color: "#ADADAD", fontSize: "10px" }}
              >
                <strong>
                  Control <br /> Toothpaste*
                </strong>
              </Paragraph>
            </Flex>
          )}
        </Comparison>
      </AspectRatio>
      {legend && !mini && (
        <>
          {legend.map((data, index) => (
            <Legend
              label={data.label}
              key={index}
              sx={{ p: isMobile ? 3 : 3, pb: 0 }}
            >
              {data.values.map(({ color, label }) => (
                <Legend.Value color={color} key={label}>
                  {label}
                </Legend.Value>
              ))}
            </Legend>
          ))}
        </>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          ml: isMobile ? 3 : 3,
          mt: isMobile ? 3 : 0,
          fontSize: "13px",
        }}
      >
        <Paragraph key="sodium67">^with 67% sodium bicarbonate</Paragraph>
        <Paragraph key="sodium0" sx={{ mt: 1, pb: 2 }}>
          *with 0% sodium bicarbonate
        </Paragraph>
      </Box>
    </Box>
  )
}
