import { useCallback, useMemo } from "react"

import { BarChart } from "components"

import ReductionChart from "../ReductionChart"

export default function RelativeReductionChart({
  clinicalMeasure,
  mini,
  value,
  isMobile,
  ...otherProps
}) {
  const chartProps = useMemo(() => {
    const range = clinicalMeasure.ranges.relative
    return { minValue: range?.min, maxValue: range?.max }
  }, [clinicalMeasure])

  const formatData = useCallback(
    (base) =>
      base.control.percent.map((value, index) => {
        const control = value
        const parodontax = base.parodontax.percent[index]

        return {
          id: index,
          control,
          parodontax,
        }
      }),
    [],
  )

  const formatValues = useCallback(
    (base, index) => ({
      control: Math.round(base.control.percent[index] * 100),
      parodontax: Math.round(base.parodontax.percent[index] * 100),
    }),
    [],
  )

  return (
    <ReductionChart
      isMobile={isMobile}
      chartComponent={BarChart}
      chartProps={chartProps}
      clinicalMeasure={clinicalMeasure}
      formatData={formatData}
      formatValues={formatValues}
      mini={mini}
      title={`% Reduction of ${clinicalMeasure.label.toLowerCase()} after 24 weeks`}
      value={value}
      valuesLabel="Change from baseline %"
      {...otherProps}
    />
  )
}
