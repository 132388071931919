import { useEffect } from "react"
import { useLocation } from "react-router-dom"

/**
 * ScrollToTop
 * Scrolls to the top of the page on route change
 * @returns {null}
 * @constructor
 */
export default function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
