import { ResponsiveBar } from "@nivo/bar"
import { useMemo } from "react"

import theme from "theme"

export default function BarChart({
  data,
  leftAxisLabel,
  mini,
  onResize,
  ...otherProps
}) {
  const isMobile = window.innerWidth < 900
  return useMemo(() => {
    return (
      <ResponsiveBar
        ariaLabel="Bar Chart"
        axisBottom={false}
        axisLeft={
          mini
            ? false
            : {
                format: ".0%",
                legend: leftAxisLabel,
                legendOffset: -42,
                tickSize: 0,
                tickValues: 5,
              }
        }
        barComponent={({
          bar: {
            color,
            data: { index },
            height,
            width,
            x,
            y,
          },
        }) => {
          if (color === theme.colors.red) {
            x -= (width * 1) / 8
            width /= 4
          } else {
            x += width / 2
          }

          if (index === 0) {
            onResize?.(x + width / 2)
          }

          return <rect fill={color} height={height} width={width} x={x} y={y} />
        }}
        colors={[theme.colors.gray, theme.colors.red]}
        data={data}
        enableLabel={false}
        groupMode="grouped"
        keys={["control", "parodontax"]}
        labelSkipHeight={20}
        margin={
          mini
            ? { bottom: 0, left: 0, right: 0, top: 0 }
            : isMobile
            ? { top: 32, right: 16, bottom: 32, left: 28 }
            : { bottom: 32, left: 64, right: 48, top: 32 }
        }
        role="application"
        valueFormat=".0%"
        {...otherProps}
      />
    )
  }, [data, leftAxisLabel, mini, onResize, otherProps, isMobile])
}
