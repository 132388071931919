import PropTypes from "prop-types"
import { useCallback } from "react"
import { Link as RouterLink } from "react-router-dom"
import { Link as ThemeLink } from "theme-ui"

import { trackEvent } from "utils/tracking"

/**
 * Link with tracking
 * Will try to detect internal/external links and treat as necessary.
 * Internal links should return a React Router Link component.
 * External links return an 'a' tag with added props.
 * Hash links also return an 'a' tag.
 * @param children
 * @param href
 * @param onClick
 * @param to
 * @param trackingLabel   Tracks as "${trackingLabel} Link Clicked".
 * @param trackingParams  An object of parameters to track if needed.
 * @param otherProps
 * @returns {JSX.Element}
 * @constructor
 */
export default function Link({
  children,
  href,
  onClick,
  to,
  trackingLabel,
  trackingParams,
  ...otherProps
}) {
  to = to || href

  const isHash = typeof to === "string" && to.substr(0, 1) === "#"
  const isAbsoluteLink = typeof to === "string" && to.includes("//")
  const isExternalLink = isAbsoluteLink && !to.includes(window.location.host)
  const isInternalLink = !!to && !isExternalLink

  const handleClick = useCallback(
    (event) => {
      trackingLabel &&
        trackEvent(`${trackingLabel} Link Clicked`, trackingParams)
      onClick?.(event)
    },
    [onClick, trackingLabel, trackingParams],
  )

  return (
    <ThemeLink
      as={isInternalLink ? RouterLink : "a"}
      href={isExternalLink || isHash ? to : null}
      onClick={handleClick}
      rel={isExternalLink ? "noopener noreferrer" : null}
      target={isExternalLink ? "_blank" : null}
      to={isInternalLink ? to : null}
      {...otherProps}
    >
      {children}
    </ThemeLink>
  )
}

Link.propTypes = {
  trackingLabel: PropTypes.string.isRequired,
}
