export const PLAQUE = {
  label: "Turesky Plaque Index heatmap",
  values: [
    { color: "#FFFED8", label: "0.00-0.99" },
    { color: "#F8D998", label: "1.00-1.99" },
    { color: "#F19D45", label: "2.00-2.99" },
    { color: "#CB662B", label: "3.00-3.99" },
    { color: "#993404", label: "≥ 4.00" },
  ],
}

export const BLEEDING = {
  label: "Bleeding Index heatmap",
  values: [
    { color: "#B9BCDF", label: "< 0.50" },
    { color: "#7C86B7", label: "0.50-0.99" },
    { color: "#394684", label: "≥ 1.00" },
  ],
}

export const INFLAMMATION = {
  label: "Modified Gingival Index heatmap",
  values: [
    { color: "#FAE6DB", label: "0.00-0.99" },
    { color: "#EA9FCD", label: "1.00-1.99" },
    { color: "#B53287", label: "2.00-2.99" },
    { color: "#6F1873", label: "≥ 3.00" },
  ],
}
