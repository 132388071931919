import { Global } from "@emotion/react"

/**
 * Global Styles
 * Generally, you should try to avoid adding CSS to global scope.
 * Many styles can be safely encapsulated into a component without the need for global styles.
 * See https://theme-ui.com/guides/global-styles for more.
 * @returns {JSX.Element}
 * @constructor
 */
export default function GlobalStyles() {
  return (
    <Global
      styles={(theme) => ({
        "*": {
          boxSizing: "border-box",
          userSelect: "none",
        },
        "html, body, #app-root": {
          height: "100%", // 100vh causes issues with collapsing browser chrome.
        },
        button: {
          cursor: "pointer",
        },
        code: {
          paddingLeft: "0.1em",
          paddingRight: "0.1em",
          backgroundColor: theme.colors.muted,
          borderRadius: "0.25em",
          fontSize: "1.2em",
        },
        "#app-root": {
          display: "flex",
          flexDirection: "column",
        },
      })}
    />
  )
}
