import { useCallback, useMemo } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Flex } from "theme-ui"

import { Select, Toggle } from "components"

import {
  CLINICAL_MEASURE,
  MOUTH_VIEW,
  PRIMARY_VIEW,
  TIME,
  TYPES,
} from "config/tools"

import useSearchParams from "hooks/useSearchParams"
import useToolParam from "hooks/useToolParam"

import { trackEvent } from "utils/tracking"

const TOOLS = [
  [CLINICAL_MEASURE, PRIMARY_VIEW],
  [MOUTH_VIEW, TIME],
]

export default function Tools({ ...otherProps }) {
  const history = useHistory()
  const location = useLocation()
  const searchParams = useSearchParams()
  const primaryView = useToolParam(PRIMARY_VIEW)
  const clinicalMeasure = useToolParam(CLINICAL_MEASURE)
  const isMobile = window.innerWidth < 900

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      trackEvent("Tool Changed", { name, value })
      const params = new URLSearchParams(location.search)
      params.set(name, value)
      history.replace({ ...location, search: params.toString() })
    },
    [history, location],
  )

  const disabledTools = useMemo(() => {
    const tools = []
    switch (primaryView) {
      case PRIMARY_VIEW.options.absoluteReductionChart:
      case PRIMARY_VIEW.options.percentageReductionChart:
        // GPMMP2-26 point 6: Asked not to disable to Time toggle
        // tools.push(TIME.id)
        break
      default:
        break
    }
    return tools
  }, [primaryView])

  const disabledOptions = useMemo(() => {
    const options = []
    switch (primaryView.id) {
      case "absolute-reduction-chart":
      case "percentage-reduction-chart":
        options.push("plaque-and-bleeding", "plaque-and-inflammation")
        break
      default:
        break
    }
    switch (clinicalMeasure.id) {
      case "plaque-and-bleeding":
      case "plaque-and-inflammation":
        options.push("absolute-reduction-chart", "percentage-reduction-chart")
        break
      default:
        break
    }
    return options
  }, [primaryView, clinicalMeasure])

  const overrideValues = useMemo(() => {
    const values = {}
    switch (primaryView) {
      case PRIMARY_VIEW.options.absoluteReductionChart.id:
      case PRIMARY_VIEW.options.percentageReductionChart.id:
        values[TIME.id] = TIME.options.weeks24.id
        break
      default:
        break
    }
    return values
  }, [primaryView])

  const defaultValues = {
    [TIME.id]: TIME.options.weeks24.id,
  }

  return (
    <Flex
      __css={{ flexDirection: ["column", null, null, null, "row"], gap: 3 }}
      {...otherProps}
    >
      {TOOLS.map((group, index) => (
        <Flex
          key={`group-${index}`}
          sx={{ flexDirection: ["column", "row"], gap: 3 }}
        >
          {group.map(({ id, label, options, type }) => {
            options = Object.values(options)
            let Parent, Child

            switch (type) {
              default:
              case TYPES.SELECT:
                Parent = Select
                Child = Select.Option
                break
              case TYPES.TOGGLE:
                Parent = Toggle
                Child = Toggle.Option
                break
            }

            return (
              <Parent
                disabled={disabledTools.includes(id)}
                key={id}
                label={label}
                name={id}
                onChange={handleChange}
                isMobile={isMobile}
                value={
                  overrideValues[id] ||
                  searchParams.get(id) ||
                  defaultValues[id] ||
                  options[0].id
                }
              >
                {options.map(({ id, label }) => (
                  <Child
                    isMobile={isMobile}
                    disabled={disabledOptions.includes(id)}
                    key={id}
                    value={id}
                  >
                    {label}
                  </Child>
                ))}
              </Parent>
            )
          })}
        </Flex>
      ))}
    </Flex>
  )
}
