import { EmbeddedContext } from "App"
import { useContext, useMemo, useState } from "react"
import { Box, Card, Container, Flex, Paragraph, Image } from "theme-ui"

import { Footer, Header, Lockup, Screen, Button } from "components"

import { CLINICAL_MEASURE, MOUTH_VIEW, PRIMARY_VIEW, TIME } from "config/tools"

import useToolParam from "hooks/useToolParam"

import filter from "images/filter.svg"
import fade from "images/header.svg"

import { brand } from "utils/url"

import ImageComparison from "./ImageComparison/ImageComparison"
import AbsoluteReductionChart from "./ReductionChart/Absolute/AbsoluteReductionChart"
import RelativeReductionChart from "./ReductionChart/Relative/RelativeReductionChart"
import Tools from "./Tools/Tools"

export default function HomeScreen() {
  const isEmbedded = useContext(EmbeddedContext)
  const [sliderValue, setSliderValue] = useState()
  const [singleChart, setSingleChart] = useState(false)
  const [showFilter, setShowFilter] = useState(false)

  const clinicalMeasure = useToolParam(CLINICAL_MEASURE)
  const primaryView = useToolParam(PRIMARY_VIEW)
  const mouthView = useToolParam(MOUTH_VIEW)
  const time = useToolParam(TIME)

  const isMobile = window.innerWidth < 900

  const graph = useMemo(() => {
    switch (primaryView) {
      default:
        return (
          <ImageComparison
            legend={clinicalMeasure.legend}
            onChange={setSliderValue}
            title={`${clinicalMeasure.label} ${
              time === TIME.options.weeks24
                ? "scores at week 24 - Control vs " + brand + " Toothpaste"
                : "scores at week 0 - Control vs " + brand + " Toothpaste"
            }`}
            {...{ clinicalMeasure, primaryView, mouthView, time }}
          />
        )
      case PRIMARY_VIEW.options.absoluteReductionChart:
        return (
          <AbsoluteReductionChart
            isMobile={isMobile}
            scoreChart={true}
            singleChart={singleChart}
            data={clinicalMeasure.data}
            onChange={setSliderValue}
            {...{ clinicalMeasure, mouthView }}
          />
        )
      case PRIMARY_VIEW.options.percentageReductionChart:
        return (
          <RelativeReductionChart
            data={clinicalMeasure.data}
            singleChart={singleChart}
            onChange={setSliderValue}
            {...{ clinicalMeasure, mouthView }}
          />
        )
    }
  }, [
    clinicalMeasure,
    mouthView,
    primaryView,
    setSliderValue,
    singleChart,
    time,
    isMobile,
  ])

  const singleGraph = useMemo(() => {
    switch (clinicalMeasure.id) {
      default:
        return false
      case "plaque-and-bleeding":
      case "inflammation":
      case "bleeding":
      case "plaque":
      case "plaque-and-inflammation":
        switch (time.id) {
          default:
            return false
          case "0-weeks":
            return true
        }
    }
  }, [clinicalMeasure, time])

  const miniGraph = useMemo(() => {
    switch (primaryView) {
      default:
        return (
          <RelativeReductionChart
            mini
            primaryView={PRIMARY_VIEW.options.mouthMap}
            value={sliderValue}
            valuesLabel=""
            {...{ clinicalMeasure, mouthView, time }}
          />
        )
      case PRIMARY_VIEW.options.dentalChart:
        return (
          <AbsoluteReductionChart
            isMobile={isMobile}
            mini
            data={clinicalMeasure.data}
            value={sliderValue}
            mouthMap={true}
            {...{ clinicalMeasure, mouthView }}
          />
        )
      case PRIMARY_VIEW.options.mouthMap:
        return (
          <AbsoluteReductionChart
            isMobile={isMobile}
            mini
            data={clinicalMeasure.data}
            value={sliderValue}
            mouthMap={true}
            {...{ clinicalMeasure, mouthView }}
          />
        )
      case PRIMARY_VIEW.options.percentageReductionChart:
      case PRIMARY_VIEW.options.absoluteReductionChart:
        setSingleChart(true)
        return (
          <ImageComparison
            mini
            primaryView={PRIMARY_VIEW.options.mouthMap}
            value={sliderValue}
            singleChart={singleChart}
            singleGraph={singleGraph}
            {...{ clinicalMeasure, mouthView, time }}
          />
        )
    }
  }, [
    clinicalMeasure,
    mouthView,
    primaryView,
    sliderValue,
    time,
    singleChart,
    singleGraph,
    isMobile,
  ])

  const miniGraphTitle = useMemo(() => {
    switch (primaryView) {
      case PRIMARY_VIEW.options.mouthMap:
      case PRIMARY_VIEW.options.dentalChart:
        switch (clinicalMeasure) {
          case CLINICAL_MEASURE.options.bleeding:
            return "Bleeding scores at week 0 & 24"
          case CLINICAL_MEASURE.options.plaqueAndBleeding:
            return "Bleeding scores at week 0 & 24"
          case CLINICAL_MEASURE.options.inflammation:
          case CLINICAL_MEASURE.options.plaqueAndInflammation:
            return "Inflammation scores at week 0 & 24"
          case CLINICAL_MEASURE.options.plaque:
            return "Plaque scores at week 0 & 24"
          default:
            return
        }
      default:
        return `${clinicalMeasure.label} scores at week 24`
    }
  }, [clinicalMeasure, primaryView])

  return (
    <Screen sx={{ minHeight: "100%" }} title="Tool" trackingLabel="Tool">
      <Flex
        sx={{
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: !singleGraph ? "100%" : "inherit",
        }}
      >
        <Header
          sx={{
            backgroundImage: `url(${fade})`,
            backgroundRepeat: "no-repeat",
            height: 40,
            backgroundSize: "92.5%",
          }}
        />
        <Container pb={5} pt={4}>
          {!isEmbedded && (
            <Lockup
              title="67% Sodium Bicarbonate Toothpaste"
              sx={{
                alignItems: "flex-end",
                // display: ["block", null, null, null, "flex"],
                fontSize: "0.75em",
                justifyContent: "space-between",
                p: 3,
                pt: 0,
              }}
            />
          )}
          {isMobile && (
            <Box>
              <Button
                onClick={() => setShowFilter(showFilter ? false : true)}
                sx={{
                  p: "8px 10px 8px 10px",
                  backgroundColor: "secondary",
                  borderRadius: "12px 12px 0px 0px",
                  ml: 3,
                  "&:hover": {
                    backgroundColor: "secondary",
                  },
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Paragraph>{showFilter ? "Close" : "Filter"}</Paragraph>
                  <Image
                    src={filter}
                    sx={{
                      ml: 3,
                      width: "14px",
                    }}
                  />
                </Box>
              </Button>
              {showFilter && (
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: 1,
                    width: "100%",
                    maxWidth: "450px",
                  }}
                >
                  <Box
                    sx={{
                      mr: 3,
                      ml: 3,
                      backgroundColor: "white",
                      p: 3,
                      borderRadius: "0px 0px 12px 12px",
                    }}
                  >
                    <Tools />
                  </Box>
                </Box>
              )}
            </Box>
          )}
          <Flex
            sx={{
              flexDirection: isMobile ? "column-reverse" : "column",
              gap: isMobile ? 0 : 4,
              justifyContent: "center",
              minHeight: "100%",
            }}
          >
            <Flex
              sx={{
                alignItems: "flex-start",
                flexDirection: ["column", null, null, "row"],
                gap: 4,
                justifyContent: "space-between",
              }}
            >
              {!isMobile && <Tools />}
              {!singleGraph && (
                <Card
                  sx={{
                    display: ["block", null, null, "block", "none"],
                    p: 3,
                    width: isMobile ? "100%" : "300px",
                    borderRadius: isMobile ? "0px" : "24px",
                    filter: isMobile && showFilter ? "brightness(30%)" : "none",
                    borderTop: isMobile ? "none" : "1px solid #E7E8E9",
                  }}
                >
                  <Paragraph
                    sx={{
                      mb: isMobile ? 0 : 2,
                      textAlign: isMobile ? "left" : "center",
                    }}
                  >
                    {!isMobile && miniGraphTitle}
                  </Paragraph>
                  {miniGraph}
                </Card>
              )}
            </Flex>
            <Card
              sx={{
                alignItems: "center",
                display: "flex",
                gap: 3,
                p: [0, null, 3],
                borderRadius: isMobile ? "0px" : "24px",
                filter: isMobile && showFilter ? "brightness(30%)" : "none",
                borderBottom: isMobile ? "none" : "1px solid #E7E8E9",
              }}
            >
              <Flex
                sx={{
                  flexDirection: "column",
                  flexGrow: 1,
                  gap: 5,
                  py: [2, null, 3],
                }}
              >
                {graph}
              </Flex>
              {miniGraph && (
                <Box
                  sx={{
                    opacity: !singleGraph ? 1 : 0,
                    display: ["none", null, null, null, "block"],
                    flexShrink: 0,
                    mr: 4,
                    mt: -100,
                    width: 383,
                  }}
                >
                  <Paragraph
                    sx={{
                      mb:
                        primaryView.id === "mouth-map" ||
                        primaryView.id === "dental-chart"
                          ? 5
                          : "-80px",
                      textAlign: "center",
                    }}
                  >
                    {}
                  </Paragraph>
                  {miniGraph}
                </Box>
              )}
            </Card>
          </Flex>
          <Paragraph sx={{ mt: 4, mr: isMobile ? 3 : 0, ml: isMobile ? 3 : 0 }}>
            Reference: Parkinson C et al. Pooled -analysis of anti-gingivitis
            effects of a 67% sodium bicarbonate dentifrice. Poster No 1294.
            Presented at Virtual IADR July 2021 <br /> <br />
            Patients received a professional clean at baseline and followed a
            twice-daily brushing regime for 24 weeks
          </Paragraph>
        </Container>
        <Footer />
      </Flex>
    </Screen>
  )
}
