import { __DEV__ } from "utils/env"

/**
 * Track an event with Google Tag Manager
 * @param label
 * @param params
 */
export const trackEvent = (label, params) => {
  label && window.dataLayer.push({ event: label, ...params })
  if (__DEV__) console.info("Tracking:", label, params || "")
}
