import { Box, Heading, Image } from "theme-ui"

import logo_corsodyl from "images/logo_corsodyl.svg"
import logo_parodontax from "images/logo_parodontax.svg"

import { brand } from "utils/url"

const logo = brand === "Parodontax" ? logo_parodontax : logo_corsodyl

export default function Lockup({ ...otherProps }) {
  let splitTitle = otherProps.title.split("\\n").map((text, index) => {
    return <p key={index}>{text}</p>
  })
  return (
    <Box {...otherProps}>
      <Image
        alt="parodontax"
        src={logo}
        sx={{ display: "block", height: "3.3125em", mb: 2, ml: "2px" }}
      />
      <Heading
        as="h2"
        sx={{
          fontSize: "1.375em",
          fontWeight: 700,
          mb: 3,
          textTransform: "uppercase",
        }}
      >
        {splitTitle}
      </Heading>
    </Box>
  )
}
