import PropTypes from "prop-types"
import { useEffect } from "react"
import { Box } from "theme-ui"

import { trackEvent } from "utils/tracking"
import { app_title } from "utils/url"

/**
 * Screen with tracking and page title
 * @param children
 * @param title           Page title
 * @param trackingLabel   Tracks as "${trackingLabel} Screen Viewed".
 * @param trackingParams  An object of parameters to track if needed.
 * @param otherProps
 * @returns {JSX.Element}
 * @constructor
 */
export default function Screen({
  children,
  title,
  trackingLabel,
  trackingParams,
  ...otherProps
}) {
  // Update title...
  useEffect(() => {
    if (title) document.title = `${app_title} – ${title}`
  }, [title])

  // Track screen...
  useEffect(() => {
    trackingLabel &&
      trackEvent(`${trackingLabel} Screen Viewed`, trackingParams)
  }, [trackingLabel, trackingParams])

  return (
    <Box as="main" {...otherProps}>
      {children}
    </Box>
  )
}

Screen.propTypes = {
  title: PropTypes.string.isRequired,
  trackingLabel: PropTypes.string.isRequired,
}
