import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Box, Flex, Paragraph } from "theme-ui"

import { Legend, Slider, Toggle } from "components"

import theme from "theme"

import { brand } from "utils/url"

import Values from "./Values/Values"

export default function ReductionChart({
  chartComponent: ChartComponent,
  chartProps,
  clinicalMeasure,
  formatData,
  formatValues,
  mini,
  mouthMap,
  scoreChart,
  singleChart,
  mouthView,
  onChange,
  title,
  value,
  isMobile,
  valuesLabel,
}) {
  const sliderBox = useRef()
  const [selected, setSelected] = useState()
  const [toggleValue, setToggleValue] = useState("top")

  const handleChartChange = useCallback(
    (value) => {
      setToggleValue(value.target.value)
    },
    [setToggleValue],
  )

  const base = useMemo(
    () => clinicalMeasure.data[mouthView.id],
    [clinicalMeasure.data, mouthView],
  )

  const lowerData = useMemo(
    () => formatData(base.lower),
    [base.lower, formatData],
  )

  const upperData = useMemo(
    () => formatData(base.upper),
    [base.upper, formatData],
  )

  const handleResize = useCallback(
    (value) => {
      const style = sliderBox.current?.style
      if (style && !isMobile) style.left = style.right = `${value}px`
      if (style && isMobile) {
        style.left = `${6}px`
        style.right = `${2}px`
      }
    },
    [isMobile],
  )

  const upperChart = useMemo(
    () => (
      <ChartComponent
        data={upperData}
        mini={mini}
        // leftAxisLabel={!mini ? "Upper" : null}
        onResize={handleResize}
        {...chartProps}
      />
    ),
    [chartProps, handleResize, mini, upperData],
  )

  const lowerChart = useMemo(
    () => (
      <ChartComponent
        data={lowerData}
        mini={mini}
        // leftAxisLabel={!mini ? "Lower" : null}
        {...chartProps}
      />
    ),
    [chartProps, lowerData, mini],
  )

  const upperValues = useMemo(
    () => formatValues(base.upper, selected),
    [base.upper, formatValues, selected],
  )

  const lowerValues = useMemo(
    () => formatValues(base.lower, selected),
    [base.lower, formatValues, selected],
  )

  const max = base.upper.control.start.length - 1

  const handleChange = useCallback(
    (value) => {
      setSelected(value)
      onChange?.(value)
    },
    [onChange, setSelected],
  )

  useEffect(() => {
    if (value !== undefined) handleChange(value)
  }, [handleChange, value])

  return useMemo(() => {
    return (
      <Flex sx={{ flexDirection: "column", width: "100%" }}>
        {!mini && (
          <Paragraph
            sx={{
              mb: 2,
              mx: isMobile ? 3 : 5,
              textAlign: isMobile ? "left" : "center",
            }}
          >
            {title}
          </Paragraph>
        )}
        <Flex
          sx={{
            flexDirection: mini
              ? isMobile
                ? "column-reverse"
                : "row-reverse"
              : isMobile
              ? "column-reverse"
              : "row",
            gap: mini ? 2 : 0,
            position: "relative",
            pb: mini ? 4 : 0,
            mb: isMobile ? 3 : 0,
            pr: mini ? 0 : [2, null, 4],
            pl: mini ? 0 : [2, null, 0],
          }}
        >
          <Flex
            sx={{
              flex: "1 1 100%",
              flexDirection: "column",
              gap: [2, null, 7],
              ml: [0, null, 0],
              mr: [0, null, 0],
              position: "relative",
            }}
          >
            {!isMobile ? (
              <>
                <Flex sx={{ height: mini ? 93 : 200 }}>{upperChart}</Flex>
                <Flex sx={{ height: mini ? 93 : 200 }}>{lowerChart}</Flex>
              </>
            ) : toggleValue === "top" ? (
              <Flex sx={{ height: mini ? 93 : 200 }}>{upperChart}</Flex>
            ) : (
              <Flex sx={{ height: mini ? 93 : 200 }}>{lowerChart}</Flex>
            )}
            <Box
              sx={{
                bottom: 0,
                left: 0,
                position: "absolute",
                right: 0,
                top: 0,
              }}
              ref={sliderBox}
            >
              <Slider
                max={max}
                onChange={handleChange}
                passive={mini}
                step={1}
                sx={
                  !mini
                    ? isMobile
                      ? { left: 34, right: 28 }
                      : { left: 64, right: 48 }
                    : { ml: 20 }
                }
                value={value}
              />
            </Box>
          </Flex>
          <Flex
            sx={{
              flexBasis: mini ? 72 : [72, null, 72],
              flexDirection: "column",
              flexGrow: 0,
              flexShrink: 0,
              gap: [2, null, 4],
              pr: isMobile ? 0 : 6,
              justifyContent: "space-around",
            }}
          >
            {isMobile ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {toggleValue === "top" ? (
                  <Values
                    label={valuesLabel}
                    mini={mini}
                    mouthMap={mouthMap}
                    scoreChart={scoreChart}
                    values={upperValues}
                  />
                ) : (
                  <Values
                    label={valuesLabel}
                    mini={mini}
                    mouthMap={mouthMap}
                    scoreChart={scoreChart}
                    values={lowerValues}
                  />
                )}
                <Box sx={{ display: "flex" }}>
                  <Toggle
                    id="single-chart"
                    value={toggleValue}
                    onChange={handleChartChange}
                  >
                    <Toggle.Option key="top" value="top">
                      Top
                    </Toggle.Option>
                    <Toggle.Option value="bottom" key="bottom">
                      Bottom
                    </Toggle.Option>
                  </Toggle>
                </Box>
              </Box>
            ) : (
              <>
                <Values
                  label={valuesLabel}
                  mini={mini}
                  mouthMap={mouthMap}
                  scoreChart={scoreChart}
                  values={upperValues}
                />
                <Values
                  label={valuesLabel}
                  mini={mini}
                  mouthMap={mouthMap}
                  scoreChart={scoreChart}
                  values={lowerValues}
                />
              </>
            )}
            <Legend
              sx={{
                minWidth: "160px",
                bottom: 0,
                position: "absolute",
                width: isMobile ? "none" : 50,
                mb: singleChart || isMobile ? -18 : -35,
                ml: singleChart || isMobile ? "6px" : 80,
              }}
            >
              <Legend.Value color={theme.colors.gray}>
                Control toothpaste*
              </Legend.Value>
              <Legend.Value color={theme.colors.red}>
                {brand} toothpaste^
              </Legend.Value>
            </Legend>
          </Flex>
        </Flex>
      </Flex>
    )
  }, [
    toggleValue,
    handleChartChange,
    isMobile,
    handleChange,
    lowerChart,
    lowerValues,
    max,
    mini,
    title,
    mouthMap,
    scoreChart,
    upperChart,
    singleChart,
    upperValues,
    valuesLabel,
    value,
  ])
}
