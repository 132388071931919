import React from "react"
import { Box, Flex, Paragraph } from "theme-ui"

import ToggleOption from "./Option/ToggleOption"

export default function Toggle({
  children,
  disabled,
  label,
  name,
  onChange,
  value,
  ...otherProps
}) {
  return (
    <Box
      as="fieldset"
      __css={{
        border: "none",
        opacity: disabled && 0.5,
        p: 0,
      }}
      {...otherProps}
    >
      <Paragraph as="legend" sx={{ mb: 2, ml: 0 }} variant="label">
        {label}
      </Paragraph>
      <Flex
        sx={{
          backgroundColor: "white",
          border: "1px solid #E7E8E9",
          borderRadius: 1000,
          mt: "-2px",
          p: "1px",
          pointerEvents: disabled && "none",
        }}
      >
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            checked: child.props.value === value,
            disabled,
            name,
            onChange,
          }),
        )}
      </Flex>
    </Box>
  )
}

Toggle.Option = ToggleOption
