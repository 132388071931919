import { Flex } from "theme-ui"

import LegendLabel from "./Label/LegendLabel"
import LegendValue from "./Value/LegendValue"

export default function Legend({ children, label, ...otherProps }) {
  return (
    <Flex __css={{ flexWrap: "wrap", mb: -1 }} {...otherProps}>
      {!!label && (
        <LegendLabel mb={2} mr={2}>
          {label}
        </LegendLabel>
      )}
      <Flex __css={{ flexWrap: "wrap" }}>{children}</Flex>
    </Flex>
  )
}

Legend.Value = LegendValue
Legend.Label = LegendLabel
