import { DotsItem } from "@nivo/core"
import { ResponsiveLine } from "@nivo/line"
import { useMemo } from "react"

import theme from "theme"

export default function LineChart({
  data,
  leftAxisLabel,
  mini,
  ...otherProps
}) {
  const isMobile = window.innerWidth < 900
  // eslint-disable-next-line
  const sortData = () => {
    let sortedArray = []
    if (isMobile) {
      // eslint-disable-next-line
      data.filter((item) => {
        if (parseInt(item.id.split("-")[1]) % 2) {
          sortedArray.push(item)
          return item
        }
      })
      return sortedArray
    }
    return data
  }
  // eslint-disable-next-line
  const Symbol = (data, height, width) => {
    height = "4px"
    width = "4px"
    return (
      <rect
        fill={data.color}
        height={height}
        width={width}
        x={"-2"}
        rx={"20"}
      />
    )
  }

  const customSymbol = ({ points, ...props }) => {
    const shownPoints = points

    return (
      <g>
        {
          // eslint-disable-next-line
        } {shownPoints.map((point) => {
          if (point.index % 2) {
            return (
              <DotsItem
                key={point.id}
                x={point.x}
                y={point.y}
                datum={point.data}
                symbol={props.pointSymbol}
                size={props.pointSize}
                color={point.color}
                borderWidth={props.pointBorderWidth}
                borderColor={point.borderColor}
                label={point.label}
                labelYOffset={props.pointLabelYOffset}
              />
            )
          }
        })}
      </g>
    )
  }
  return useMemo(
    () => (
      <ResponsiveLine
        ariaLabel="Line Chart"
        axisBottom={false}
        axisLeft={{
          legend: leftAxisLabel,
          legendOffset: -42,
          tickSize: 0,
          tickValues: 7,
        }}
        lineWidth={1}
        colors={[theme.colors.gray, theme.colors.red]}
        data={sortData()}
        enableGridX={false}
        pointSymbol={Symbol}
        layers={[
          "grid",
          "axes",
          "areas",
          "lines",
          "crosshair",
          "slices",
          "mesh",
          "legends",
          customSymbol,
        ]}
        margin={
          mini
            ? { bottom: 5, left: 20, right: 0, top: 5 }
            : isMobile
            ? { top: 32, right: 16, bottom: 32, left: 28 }
            : { top: 32, right: 48, bottom: 32, left: 64 }
        }
        pointBorderColor={{ from: "serieColor" }}
        role="application"
        xScale={{ type: "linear" }}
        {...otherProps}
      />
    ),
    [mini, leftAxisLabel, otherProps, Symbol, sortData, isMobile],
  )
}
