import { useCallback, useEffect, useRef } from "react"
import { Box } from "theme-ui"

import { Slider } from "components"

export default function Comparison({
  a,
  b,
  children,
  mini,
  onChange,
  value,
  ...otherProps
}) {
  const isMobile = window.innerWidth < 900
  const maskRef = useRef()

  const update = useCallback((value) => {
    const percent = value * 100
    maskRef.current.style.clipPath = `polygon(0% 0%, ${percent}% 0%, ${percent}% 100%, 0% 100%)`
  }, [])

  const handleChange = useCallback(
    (value) => {
      update(value)
      onChange?.(value)
    },
    [onChange, update],
  )

  useEffect(() => {
    if (value !== undefined) update(value)
  }, [update, value])

  return (
    <Box
      __css={{
        px: mini ? 0 : isMobile ? 16 : 32,
        pointerEvents: "none",
        position: "relative",
      }}
    >
      {a}
      <Box
        sx={{
          bottom: 0,
          left: 0,
          mx: mini ? 0 : isMobile ? 16 : 32,
          position: "absolute",
          right: 0,
          top: 0,
        }}
      >
        <Box
          ref={maskRef}
          sx={{
            bottom: 0,
            left: 0,
            overflow: "hidden",
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          {b}
        </Box>
        {children}
      </Box>
      <Slider
        onChange={handleChange}
        passive={mini}
        value={value}
        {...otherProps}
      />
    </Box>
  )
}
