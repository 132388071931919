import React from "react"
import ReactDOM from "react-dom"

import App from "./App"

export const APP_ELEMENT_ID = "app-root"

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById(APP_ELEMENT_ID),
)
