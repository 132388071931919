import { Box, Paragraph, Select as ThemeSelect } from "theme-ui"

import Option from "./Option/SelectOption"

export default function Select({
  children,
  label,
  name,
  isMobile,
  ...otherProps
}) {
  return (
    <Box>
      <Paragraph
        as="label"
        htmlFor={name}
        sx={{ display: "block", pl: 3, mb: 2, ml: 1, whiteSpace: "nowrap" }}
        variant="label"
      >
        {label}
      </Paragraph>
      <Box sx={{ pl: isMobile ? 0 : 3 }}>
        <ThemeSelect
          arrow={
            <Box
              as="svg"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="white"
              sx={{
                ml: -32,
                mr: "8px",
                alignSelf: "center",
                pointerEvents: "none",
              }}
            >
              <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
            </Box>
          }
          sx={{
            backgroundColor: "secondary",
            border: "2px solid",
            borderColor: "secondary",
            borderRadius: "1000px",
            color: "white",
            cursor: "pointer",
            fontSize: 1,
            height: 48,
            pl: 3,
            pr: 5,
            width: "100%",
            "&:focus": {
              backgroundColor: "primary",
              borderColor: "primary",
              outline: "none",
            },
          }}
          name={name}
          {...otherProps}
        >
          {children}
        </ThemeSelect>
      </Box>
    </Box>
  )
}

Select.Option = Option
