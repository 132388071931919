/**
 * Get a public folder path
 * @param path
 */
export const getPublicFolderUrl = (path) => `${process.env.PUBLIC_URL}/${path}`

var host = window.location.host
export const subdomain = host.split(".")[0]

export const brand =
  subdomain.substring(0, 10) === "parodontax" ? "Parodontax" : "Corsodyl"

export const app_title = "Expert Dental Mapping | " + brand
export const app_description =
  "A visual data demonstration of the effect of " + brand

export const app_og_url =
  subdomain === "parodontax"
    ? "https://www.parodontax.com/"
    : "https://www.corsodyl.co.uk"
