import { useCallback, useMemo } from "react"

import { LineChart } from "components"

import ReductionChart from "../ReductionChart"

export default function AbsoluteReductionChart({
  clinicalMeasure,
  ...otherProps
}) {
  const isMobile = window.innerWidth < 900
  const chartProps = useMemo(() => {
    const range = clinicalMeasure.ranges.absolute
    return {
      yScale: {
        type: "linear",
        min: range?.min,
        max: range?.max,
        stacked: false,
        reverse: false,
      },
    }
  }, [clinicalMeasure])

  const formatData = useCallback(
    (base) =>
      base.control.start.reduce((output, value, index) => {
        const controlStart = value
        const controlEnd = base.control.end[index]

        const parodontaxStart = base.parodontax.start[index]
        const parodontaxEnd = base.parodontax.end[index]

        output.push({
          id: `control-${index}`,
          data: [
            { x: index, y: controlStart },
            { x: index, y: controlEnd },
          ],
        })

        output.push({
          id: `parodontax-${index}`,
          data: [
            { x: index + 0.25, y: parodontaxStart },
            { x: index + 0.25, y: parodontaxEnd },
          ],
        })

        return output
      }, []),
    [],
  )

  const formatValues = useCallback(
    (base, index) => ({
      control: Number(
        Math.round((base.control.start[index] - base.control.end[index]) * 10) /
          10,
      ).toFixed(1),
      parodontax: Number(
        Math.round(
          (base.parodontax.start[index] - base.parodontax.end[index]) * 10,
        ) / 10,
      ).toFixed(1),
    }),
    [],
  )

  return (
    <ReductionChart
      isMobile={isMobile}
      chartComponent={LineChart}
      chartProps={chartProps}
      clinicalMeasure={clinicalMeasure}
      formatData={formatData}
      formatValues={formatValues}
      title={`Scores of ${clinicalMeasure.label.toLowerCase()} at week 0 & 24`}
      valuesLabel="Actual reduction"
      {...otherProps}
    />
  )
}
