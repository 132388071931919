import { Box } from "theme-ui"

export default function Footer() {
  return (
    <Box
      sx={{
        height: 16,
        backgroundColor: "red",
        backgroundImage:
          "linear-gradient(90deg, #948F94 4%, #E3E0DD 13%, #D5D1D0 16%, #AFAAAD 22%, #948F94 26%, #F4F3F4 52%, #E6E4E5 54%, #C0BDBF 59%, #888487 64%, #F4F3F4 75%, #E6E4E5 77%, #C0BDBF 82%, #888487 87%, #AEAEAF 100%)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 4px",
      }}
    />
  )
}
