import * as LEGENDS from "config/legends"
import * as RANGES from "config/ranges"
import * as SPACINGS from "config/spacings"

import data from "data/data.json"

export const TYPES = {
  SELECT: "select",
  TOGGLE: "toggle",
}

export const CLINICAL_MEASURE = {
  id: "measure",
  label: "Clinical measure (index)",
  options: {
    plaque: {
      data: data.plaque,
      id: "plaque",
      label: "Plaque",
      legend: [LEGENDS.PLAQUE],
      spacings: SPACINGS.TEETH,
      ranges: RANGES.PLAQUE,
    },
    bleeding: {
      data: data.bleeding,
      id: "bleeding",
      label: "Bleeding",
      legend: [LEGENDS.BLEEDING],
      ranges: RANGES.BLEEDING,
      spacings: SPACINGS.GUMS,
    },
    inflammation: {
      data: data.inflammation,
      id: "inflammation",
      label: "Inflammation",
      legend: [LEGENDS.INFLAMMATION],
      ranges: RANGES.INFLAMMATION,
      spacings: SPACINGS.GUMS,
    },
  },
  type: TYPES.SELECT,
}

CLINICAL_MEASURE.options.plaqueAndBleeding = {
  ...CLINICAL_MEASURE.options.bleeding,
  legend: [LEGENDS.PLAQUE, LEGENDS.BLEEDING],
  id: "plaque-and-bleeding",
  label: "Plaque & Bleeding",
}

CLINICAL_MEASURE.options.plaqueAndInflammation = {
  ...CLINICAL_MEASURE.options.inflammation,
  legend: [LEGENDS.PLAQUE, LEGENDS.INFLAMMATION],
  id: "plaque-and-inflammation",
  label: "Plaque & Inflammation",
}

export const PRIMARY_VIEW = {
  id: "view",
  label: "Primary view",
  options: {
    mouthMap: { id: "mouth-map", label: "Mouth map" },
    dentalChart: { id: "dental-chart", label: "Dental chart" },
    absoluteReductionChart: {
      id: "absolute-reduction-chart",
      label: "Score charts",
    },
    percentageReductionChart: {
      id: "percentage-reduction-chart",
      label: "% Reduction chart",
    },
  },
  type: TYPES.SELECT,
}

export const MOUTH_VIEW = {
  id: "mouth",
  label: "Mouth view",
  options: {
    internal: { id: "internal", label: "Palatal/Lingual" },
    external: { id: "external", label: "Facial" },
  },
  type: TYPES.TOGGLE,
}

export const TIME = {
  id: "time",
  label: "Time",
  options: {
    week0: { id: "0-weeks", label: "0 weeks" },
    weeks24: { id: "24-weeks", label: "24 weeks" },
  },
  type: TYPES.TOGGLE,
}

// Value to multiply the Values for Reduction before displaying (1 - leave as is; -1 to negate the values)
export const REDUCTION_VALUE_DISPLAY_FACTOR = -1
