export const PLAQUE = {
  absolute: { min: 1.4, max: 4.5 },
  relative: { min: -0.4, max: 0.1 },
}

export const BLEEDING = {
  absolute: { min: 0, max: 1.4 },
  relative: { min: -1, max: 0 },
}

export const INFLAMMATION = {
  absolute: { min: 1.2, max: 2.8 },
  relative: { min: -0.4, max: 0.1 },
}
