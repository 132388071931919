import { Container, Heading, Image, Paragraph } from "theme-ui"

import { Link, Lockup, Screen, Header } from "components"

import routes from "config/routes"

import background from "images/background.jpg"
import fade from "images/header.svg"
import platelet from "images/platelet.svg"
import platelets from "images/platelets.svg"

import { brand } from "utils/url"

export default function HomeScreen() {
  const isMobile = window.innerWidth < 900
  return (
    <>
      <Header
        sx={{
          backgroundImage: `url(${fade})`,
          backgroundRepeat: "no-repeat",
          height: 40,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 2,
          backgroundSize: "92.5%",
        }}
      />
      <Screen
        sx={{
          alignItems: "center",
          backgroundImage: [
            null,
            null,
            `url(${background})`,
            `url(${background})`,
          ],
          backgroundPosition: "65% 0",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderBottom: "8px solid #CC112C",
          display: "flex",
          minHeight: "100%",
          position: "relative",
          "&:before": {
            background:
              "linear-gradient(90deg, rgba(255,255,255,0.75) 50%, rgba(255,255,255,0) 100%)",
            bottom: 0,
            content: "''",
            left: 0,
            position: "absolute",
            top: 0,
            width: isMobile ? 0 : 720,
            zIndex: 1,
          },
          "&:after": {
            background: `url(${platelets}) no-repeat center bottom`,
            backgroundSize: "auto 75%",
            bottom: 0,
            content: "''",
            left: 0,
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: 1,
          },
        }}
        title="Home"
        trackingLabel="Home"
      >
        <Container
          sx={{ pl: [4, 4, null, 7], position: "relative", zIndex: 2 }}
        >
          <Image alt="" src={platelet} sx={{ display: "block", mb: 4 }} />
          <Lockup title="67% Sodium Bicarbonate Toothpaste \n \nMouth Mapping" />
          <Heading
            as="h2"
            sx={{ maxWidth: [320, null, null, 480], my: 4 }}
            variant="h1"
          >
            A visual data demonstration of the effect of {brand} 67% Sodium
            Bicarbonate Toothpaste.
          </Heading>
          <Link
            to={routes.tool()}
            trackingLabel="Intro: Start"
            variant="primary"
          >
            Let&apos;s go
          </Link>
        </Container>
      </Screen>
      <Paragraph
        sx={{
          position: "absolute",
          bottom: isMobile ? 1 : 40,
          right: isMobile ? 10 : 100,
          fontWeight: "bold",
          fontSize: 30,
        }}
      >
        PM-IE-CSYL-22-00058
      </Paragraph>
    </>
  )
}
